import { getAddress } from '@ethersproject/address'
import memoize from 'lodash/memoize'
import { ChainId, Token } from '@pancakeswap/sdk'
import { BASE_S3 } from 'config'

const getTokenLogoURL = memoize(
  (token?: Token) => {
    if (token) {
      return `${BASE_S3}/images/tokens/${getAddress(token.address)}.png`
    }
    return null
  },
  (t) => `${t.chainId}#${t.address}`,
)

export default getTokenLogoURL
